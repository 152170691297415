import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { parseResponse, customFetch } from './components/http';
import { handleUris, readFileContent, createContentBlob, appendUrlsToFormData, appendFormattedTextUrlsToFormData } from './components/shared';
import { ipcRenderer, isInElectron, requestSeleniumTaskInElectron } from './components/electronHelper';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Box, Typography, Container, useTheme, Checkbox, FormControlLabel, Radio, RadioGroup, FormLabel } from '@mui/material';
import ChatDialog from './ChatDialog';
import config from './config';
import { toast } from 'react-toastify';

function Generate() {
  const theme = useTheme();

  // 样式对象，方便重用
  const commonStyles = {
    TextField: { m: 1 },
    Button: { mt: 1, ml: 1 }
  };

  const navigate = useNavigate();
  const [topic, setTopic] = useState('');
  const [filePath, setFilePath] = useState(null);
  const [filePathName, setFilePathName] = useState(''); // 用于存储文件路径的名称
  const [sampleFilePath, setSampleFilePath] = useState(null);
  const [sampleFilePathName, setSampleFilePathName] = useState(''); // 用于存储样本文件路径的名称
  const [language, setLanguage] = useState('SimplifiedChinese');
  const [styleOption, setStyleOption] = useState('Default'); // 用于存储用户选择的风格
  const [articleType, setArticleType] = useState('Default'); // 用于存储文章类型
  const [formattedTextUrls, setFormattedTextUrls] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [textUrls, setTextUrls] = useState([]);

  const [dialogOpen, setDialogOpen] = useState(false); // 用于控制对话框的显示
  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState('');
  const [articleId, setArticleId] = useState('');
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [settingIndex, setSettingIndex] = useState(-1);
  const [needMoreInfo, setNeedMoreInfo] = useState(false); // 是否需要更多信息
  const [selectOutlineByAI, setSelectOutlineByAI] = useState(false); // 是否由AI选择大纲

  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = `${config.BASE_URL}/user/user`;
      try {
        const response = await customFetch(apiUrl, { method: 'GET' });
        const result = await parseResponse(response);
        setUser(result);
        setSettingIndex(result.defaultSettingIndex);
        console.log(result);
      } catch (error) {
        console.error('Error fetching user data: ', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleTopicChange = (event) => {
    setTopic(event.target.value);
  };

  const handleFileChange = (event, setter, setName) => {
    const file = event.target.files[0];
    if (file) {
      setter(file); // 保存文件对象
      setName(file.name); // 保存文件名称
    }
  };

  const handleArticleTypeChange = (event) => {
    setArticleType(event.target.value);
  };

  // 添加URL
  const handleAddTextUrl = () => {
    setTextUrls([...textUrls, '']); // 添加一个新的空字符串到数组中
  };

  // 删除指定的URL
  const handleRemoveTextUrl = (index) => {
    const newUrls = [...textUrls];
    newUrls.splice(index, 1); // 删除指定索引的元素
    setTextUrls(newUrls);
  };

  // 更新URL值
  const handleTextUrlChange = (event, index) => {
    const newUrls = [...textUrls];
    newUrls[index] = event.target.value; // 更新指定索引的URL
    setTextUrls(newUrls);
  };

  // 添加URL
  const handleAddUrl = () => {
    setFormattedTextUrls([...formattedTextUrls, '']); // 添加一个新的空字符串到数组中
  };

  // 删除指定的URL
  const handleRemoveUrl = (index) => {
    const newUrls = [...formattedTextUrls];
    newUrls.splice(index, 1); // 删除指定索引的元素
    setFormattedTextUrls(newUrls);
  };

  // 更新URL值
  const handleUrlChange = (event, index) => {
    const newUrls = [...formattedTextUrls];
    newUrls[index] = event.target.value; // 更新指定索引的URL
    setFormattedTextUrls(newUrls);
  };

  const handleSubmit = async () => {
    if (!topic) {
      toast.error("请提供主题");
      return; // 如果没有主题，则停止执行并返回
    }

    // 检查 URL 和上传文件是否为空
    if (textUrls.length === 0 && !filePath) {
      // 如果复选框未被勾选，提示用户必须勾选
      if (!needMoreInfo) {
        toast.error("当不上传链接和文件时，必须勾选“是否需要在互联网上爬取更多信息”。");
        return;
      }
    }

    setIsButtonDisabled(true);
    try {
      var apiUrl = `${config.BASE_URL}/article/generate`;

      const formData = new FormData();
      formData.append('articleId', '');
      formData.append('topic', topic);
      formData.append('language', language);
      formData.append('articleType', articleType);
      formData.append('articleStyle', styleOption);
      formData.append('settingIndex', settingIndex);
      formData.append('needMoreInfo', needMoreInfo);
      formData.append('selectOutlineByAI', selectOutlineByAI);

      const { filelob, images } = await createContentBlob(filePath, textUrls);
      formData.append('file', filelob, 'file.txt');
      console.log(images)
      if (images && images.length > 0) {
        images.forEach((url, index) => {
          if (url && url.trim() !== '') {
            formData.append(`imageUrls[${index}]`, url);
          }
        });
      } else {
        console.log('images is null.');
      }

      const sampleFileContent = await readFileContent(sampleFilePath);
      const sampleFinalBlob = new Blob([sampleFileContent], { type: 'text/plain' });
      formData.append('sampleFile', sampleFinalBlob, 'sampleFile.txt');
      if (!isInElectron()) {
        // 适配浏览器端
        appendUrlsToFormData(textUrls, formData);
        formData.append('IsInElectron', false);
      } else {
        formData.append('IsInElectron', true);
      }

      appendFormattedTextUrlsToFormData(formattedTextUrls, formData);
      const response = await customFetch(apiUrl, {
        method: 'POST',
        body: formData
      }, true);

      if (response.ok) {
        const res = await parseResponse(response);
        if (res && res.articleId && Array.isArray(res.uris) && res.uris.length > 0) {
          await handleUris(res.articleId, res.uris, filePath, navigate);
          setDialogOpen(false);
          navigate('/articleList');
        } else if (res && res.articleId && res.message) {
          setArticleId(res.articleId);
          // TODO: refine the following code
          setDialogOpen(true);
          setMessages([
            { text: topic, fromUser: true },
            { text: res.message, fromUser: false }
          ]);
        } else {
          // Handle other cases if needed
          navigate('/articleList');
        }
      } else {
        const errorData = await parseResponse(response);
        console.error("发送请求失败:", errorData);
        throw new Error("发送请求失败:" + errorData);
      }
    } catch (error) {
      console.error('There was a problem with your fetch operation:', error);
      toast.error(`出现一点小问题: ${error.message || '未知错误'}`);
    } finally {
      setIsButtonDisabled(false);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <Box>
        <TextField
          fullWidth
          label="主题"
          value={topic}
          onChange={handleTopicChange}
          sx={commonStyles.TextField}
        />
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" component="div" sx={{ mt: 2 }}>参考链接（可选）:</Typography>
          {textUrls.map((url, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              <TextField
                fullWidth
                label={`参考链接 ${index + 1}`}
                value={url}
                onChange={(e) => handleTextUrlChange(e, index)}
                sx={{ mr: 1 }}
              />
              <Button onClick={() => handleRemoveTextUrl(index)}>-</Button>
            </Box>
          ))}
          <Button onClick={handleAddTextUrl} sx={commonStyles.Button}>+</Button>
        </Box>
        <Typography variant="body1" component="div" sx={{ mt: 2 }}>参考附件（可选）:</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
          <Button
            variant="contained"
            component="label"
            sx={{ mr: 1 }}
          >
            上传
            <input
              type="file"
              hidden
              onChange={(e) => handleFileChange(e, setFilePath, setFilePathName)}
            />
          </Button>
          {filePathName && <Typography variant="caption">{filePathName}</Typography>}
        </Box>
        <Box sx={{ mt: 2 }}>
          <FormLabel component="legend">是否允许AI搜索更多资料？</FormLabel>
          <RadioGroup row value={needMoreInfo} onChange={(e) => setNeedMoreInfo(e.target.value === 'true')}>
            <FormControlLabel value={true} control={<Radio />} label="是" />
            <FormControlLabel value={false} control={<Radio />} label="否" />
          </RadioGroup>
        </Box>
        <Box sx={{ mt: 2 }}>
          <FormLabel component="legend">是否委托AI选择大纲？</FormLabel>
          <RadioGroup row value={selectOutlineByAI} onChange={(e) => setSelectOutlineByAI(e.target.value === 'true')}>
            <FormControlLabel value={true} control={<Radio />} label="是" />
            <FormControlLabel value={false} control={<Radio />} label="否" />
          </RadioGroup>
        </Box>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>选择模板</InputLabel>
          <Select
            value={settingIndex}
            onChange={(e) => setSettingIndex(e.target.value)}
          >
            <MenuItem value={-1}>无</MenuItem>
            {user && Array.isArray(user.settings) ? (
              user.settings.map((setting, index) => (
                <MenuItem key={index} value={index}>
                  {setting.settingName}
                </MenuItem>
              ))
            ) : null}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={isButtonDisabled}
          sx={{ mt: 2, bgcolor: isButtonDisabled ? 'grey.500' : 'primary.main' }}
        >
          开始撰写
        </Button>
        <Typography variant="body2" sx={{ mt: 2 }}>
          当前剩余火种: {user?.point}颗, 本次创作扣除1颗
        </Typography>
      </Box>
      <ChatDialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        messages={messages}
        messageText={messageText}
        setMessageText={setMessageText}
        setMessages={setMessages}
        articleId={articleId}
        setDialogOpen={setDialogOpen}
        language={language}
        navigate={navigate}
        filePath={filePath}
      />
    </Container>
  );
}

export default Generate;
