import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { customFetch, parseResponse } from './components/http';
import config from './config';
import { Container, Box, Typography, CircularProgress, Alert, Button } from '@mui/material';
import { toast } from 'react-toastify';

const BindCallback = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const authCode = queryParams.get('auth_code');
  const expiresIn = queryParams.get('expires_in');

  const [loading, setLoading] = useState(true);
  const [bindResult, setBindResult] = useState(null);
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    const fetchBindResult = async () => {
      const formData = new FormData();
      formData.append('authcode', authCode);

      try {
        const response = await customFetch(`${config.BASE_URL}/bind/bind`, {
          method: 'POST',
          body: formData,
        });
        const result = await parseResponse(response);
        if (response.status === 200) {
          setBindResult({ success: true, message: '绑定成功' });
        } else {
          setBindResult({ success: false, message: result || '绑定失败' });
        }
      } catch (error) {
        setBindResult({ success: false, message: '绑定失败' });
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBindResult();

    // 倒计时功能
    const interval = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(interval);
          tryToCloseWindow();
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [authCode]);

  const tryToCloseWindow = () => {
    try {
      window.close();
      if (window.closed === false) {
        toast.warn('请手动关闭此页面');
      }
    } catch (e) {
      toast.warn('请手动关闭此页面');
    }
  };

  const handleClose = () => {
    tryToCloseWindow();
  };

  return (
    <Container maxWidth="sm">
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
        {loading ? (
          <Box display="flex" flexDirection="column" alignItems="center">
            <CircularProgress />
            <Typography variant="body1" mt={2}>
              绑定中...
            </Typography>
          </Box>
        ) : (
          <>
            <Alert severity={bindResult.success ? 'success' : 'error'}>
              {bindResult.message}
            </Alert>
            <Button variant="contained" color="primary" onClick={handleClose} sx={{ mt: 2 }}>
              {countdown > 0 ? `关闭页面 (${countdown}秒)` : '关闭页面'}
            </Button>
          </>
        )}
      </Box>
    </Container>
  );
};

export default BindCallback;
