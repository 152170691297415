import React from 'react';
import './OfficialWebsite.css';

function OfficialWebsite() {
  return (
    <div className="App">
      <header className="App-header">
        <img
          src="https://nbshunyu.blob.core.windows.net/writingclient/flamegull_logo.png"
          className="App-logo"
          alt="火鸥 logo"
        />
        <h1 className="App-title">火鸥</h1>
        <h3 className="App-subtitle">真正可靠的AI自媒体创作助手</h3>
      </header>
      <main className="App-main">
        <p className="App-description">
          只需要确定选题，火鸥帮你完成创作<br />
          从大纲草拟、搜集素材到写完全文，并提供基本排版<br />
          创作交给我，版权属于你<br />
          <strong>15分钟完成写作</strong>
        </p>
        <p className="App-slogan">
          用火鸥，会火哦！
        </p>
        <div className="download-section">
          <div className="download-option">
            <img
              src="https://nbshunyu.blob.core.windows.net/writingclient/Windows_logo_-_2012.svg.png"
              alt="Windows logo"
              className="platform-logo"
            />
            <a
              className="download-link"
              href="https://nbshunyu.blob.core.windows.net/writingclient/FlameGull_Setup_x64.exe"
              download
            >
              Windows<br />
              Windows 10/11
            </a>
          </div>
          <div className="download-option">
            <img
              src="https://nbshunyu.blob.core.windows.net/writingclient/Apple_logo_black.svg.png"
              alt="Mac logo"
              className="platform-logo"
            />
            <a
              className="download-link"
              href="https://nbshunyu.blob.core.windows.net/writingclient/FlameGull_Setup_x64.dmg"
              download
            >
              Mac<br />
              macOS 10.15+
            </a>
          </div>
        </div>
      </main>
      <footer className="App-footer">
        <div className="icp-info">
          <a href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">浙ICP备2024064818号-3</a>
          <span>|</span>
          <span>联系方式：ningboshunyu@163.com</span>
        </div>
      </footer>
    </div>
  );
}

export default OfficialWebsite;
