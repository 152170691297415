// user.js
import { customFetch, parseResponse } from './http';
import config from '../config';
export const fetchUserData = async (setResult) => {
  const apiUrl = `${config.BASE_URL}/user/user`;
  try {
    const response = await customFetch(apiUrl, { method: 'GET' });
    const result = await parseResponse(response);
    setResult(result);
    console.log(result);
  } catch (error) {
    console.error('Error fetching user data: ', error);
  }
};