import React, { useState, useEffect } from 'react';
import { parseResponse, customFetch } from './components/http';
import { List, Box, ListItem, ListItemText, CircularProgress, Container, Typography, Button, IconButton, Card, CardContent, CardActions } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import config from './config';
import PublicAccountModal from './components/PublicAccountModal';
import { fetchUserData } from './components/user.js';
import { toast } from 'react-toastify';

const ListDisplayComponent = () => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [openModal, setOpenModal] = useState(false); // 控制modal的显示
  const [articleId, setArticleId] = useState(''); // 存储选中的文章ID
  const [selectedAccount, setSelectedAccount] = useState(''); // 存储选中的公众号

  const navigate = useNavigate();

  // Get user
  useEffect(() => {
    fetchUserData(setUser);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  // Conditional interval refresh
  useEffect(() => {
    if (list.length === 0) return;

    // Determine the refresh interval based on the status of the top article
    const topArticleState = list[0].state;
    let intervalTime;

    if (topArticleState === 'Initial' ||
      topArticleState === 'UrisAdded' ||
      topArticleState === 'MaterialsAdded' ||
      topArticleState === 'GeneratingOutline') {
      intervalTime = 15000; // 15 seconds
    } else if (topArticleState === 'GeneratingContent') {
      intervalTime = 60000; // 60 seconds
    } else {
      return; // Do not set an interval if not in the required states
    }

    const intervalId = setInterval(fetchData, intervalTime);

    // Clear the interval when the component unmounts or when the list changes
    return () => clearInterval(intervalId);
  }, [list]);

  const fetchData = async () => {
    const apiUrl = `${config.BASE_URL}/article/list`;
    try {
      const response = await customFetch(apiUrl, { method: 'GET' }); // 不需要再次设置'Authorization'头
      const data = await parseResponse(response); // 使用parseResponse处理响应
      console.log(data);

      // Sort
      const sortedData = data.sort((a, b) => new Date(b.createdTime) - new Date(a.createdTime));
      console.log("sorted list:", sortedData);
      setList(sortedData);
    } catch (error) {
      console.error('Error fetching data: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (e, id) => {
    e.stopPropagation(); // Prevent event bubbling to ListItem's click event

    const apiUrl = `${config.BASE_URL}/article/${id}`;
    try {
      const response = await customFetch(apiUrl, {
        method: 'DELETE',
      });

      if (!response.ok) {
        const errorData = await parseResponse(response);
        console.error("发送请求失败:", errorData);
        throw new Error("发送请求失败:" + errorData);
      }

      // If the delete operation is successful, show an alert and then remove it from the list
      toast.success('文章删除成功！');
      setList(prevList => prevList.filter(item => item.id !== id));
    } catch (error) {
      console.error('删除操作中出现错误:', error);
      toast.error(`文章删除失败: ${error.message}`);
    }
  };

  const handleOpenModal = (e, id) => {
    e.stopPropagation(); // Prevent event bubbling to ListItem's click event
    setArticleId(id);
    setOpenModal(true);
  }

  const handleCloseModal = () => setOpenModal(false);

  return (
    <Container>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <List>
          {list.map(item => (
            <Card key={item.id} variant="outlined" sx={{ marginBottom: 2 }}>
              <Box display="flex" alignItems="center">
                <CardContent
                  onClick={() => navigate(`/article/${item.id}`)}
                  sx={{ cursor: 'pointer', flexGrow: 1, flexBasis: '60%' }}
                >
                  <Typography variant="h6">主题: {item.topic}</Typography>
                  {/* <Typography variant="body2">Id: {item.id}</Typography> */}
                  <Typography variant="body2">创建时间: {new Date(item.createdTime).toLocaleString()}</Typography>
                  {item.formattedTextUrls && item.formattedTextUrls.length > 0 && (
                    item.formattedTextUrls.map((url, index) => (
                      url && (
                        <Typography key={index} variant="body2">URL {index + 1}: {url}</Typography>
                      )
                    ))
                  )}
                </CardContent>
                <CardContent sx={{ flexBasis: '20%' }}>
                  文章状态: {
                    item.state === 'Initial' ||
                      item.state === 'UrisAdded' ||
                      item.state === 'MaterialsAdded' ||
                      item.state === 'GeneratingOutline'
                      ? '大纲生成中'
                      : item.state === 'OutlineSelection'
                        ? '等待大纲确认'
                        : item.state === 'GeneratingContent'
                          ? '文章创作中'
                          : item.state === 'Completed'
                            ? '文章已完成'
                            : item.state === 'Closed'
                              ? '文章已关闭'
                              : item.state
                  }
                </CardContent>
                <CardActions sx={{ flexBasis: '20%' }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={(e) => handleOpenModal(e, item.id)}
                    disabled={item.state !== 'Completed'}
                  >
                    同步到公众号
                  </Button>
                  <IconButton edge="end" aria-label="delete" onClick={(e) => handleDelete(e, item.id)}>
                    <DeleteIcon />
                  </IconButton>
                </CardActions>
              </Box>
            </Card>
          ))}
        </List>

      )}
      <PublicAccountModal
        open={openModal}
        onClose={handleCloseModal}
        user={user}
        articleId={articleId}
      />
    </Container>
  );
};

export default ListDisplayComponent;
