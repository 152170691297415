// config.js
const config = {
    // BASE_URL: "https://localhost:8081/api",
    // BASE_URL: "https://192.168.2.87:8081/api", // WriteAIServer (MI)
    BASE_URL: "https://flamegull.com:8443/api", // official
    // BASE_URL: "https://xhymn.com:8081/api", // WriteAIServer (nas)
    // BASE_URL: "https://xhymn.com:8888/api", // WriteAIServer dev(hangxuvm)
    // BASE_URL: "https://mulfur.com:8443/api", // Public test
    PUBLISH_URL: "https://flamegull.com:8443/api",
    ELECTRON_MIN_VERSION: '0.2.0',
};

export default config;
