import config from '../config';

// electronHelper.js
let ipcRenderer;

// 检查是否在 Electron 渲染进程中
const inElectron = window && window.process && window.process.type === 'renderer';

if (inElectron) {
  ipcRenderer = window.require('electron').ipcRenderer;
}

export function requestSeleniumTaskInElectron(url) {
  return new Promise((resolve, reject) => {
    if (ipcRenderer) {
      const listener = (event, arg) => {
        if (arg.url === url) {
          resolve(arg);
          ipcRenderer.removeListener('selenium-task-finished', listener);
        }
      };
      ipcRenderer.on('selenium-task-finished', listener);
      ipcRenderer.send('perform-selenium-task', { url });
    } else {
      reject("Not in Electron environment");
    }
  });
}

export function isInElectron() {
  return inElectron;
}

export async function getAppVersion() {
  let currentVersion;
  try {
    currentVersion = await ipcRenderer.invoke('get-app-version');
  } catch (error) {
    console.error('Error fetching app version:', error);

    // 处理未定义 get-app-version 的情况
    console.error('get-app-version is not defined in the current Electron version.');
    // 根据需要，返回一个默认值或者抛出错误
    return "";
  }

  return currentVersion;
}

export async function checkAppVersion() {
  console.log('Checking app version');
  let currentVersion;
  try {
    currentVersion = await ipcRenderer.invoke('get-app-version');
  } catch (error) {
    console.error('Error fetching app version:', error);

    // 处理未定义 get-app-version 的情况
    console.error('get-app-version is not defined in the current Electron version.');
    // 根据需要，返回一个默认值或者抛出错误
    return false;
  }

  const requiredVersion = config.ELECTRON_MIN_VERSION;
  console.log('Current version:', currentVersion);
  console.log('Required version:', requiredVersion);

  if (compareVersions(currentVersion, requiredVersion) < 0) {
    console.log('App version is too old.');
    return false;
  }

  return true;
}

// 简单的版本号比较函数
function compareVersions(v1, v2) {
  const v1Parts = v1.split('.').map(Number);
  const v2Parts = v2.split('.').map(Number);

  for (let i = 0; i < v1Parts.length; i++) {
    if (v1Parts[i] > v2Parts[i]) return 1;
    if (v1Parts[i] < v2Parts[i]) return -1;
  }
  return 0;
}

export { ipcRenderer };