import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, TextField, Typography, Container, Box, Checkbox, FormControlLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Alert, AlertTitle } from '@mui/material';
import { isInElectron, checkAppVersion } from './components/electronHelper';
import { parseResponse } from './components/http';
import config from './config';
import CryptoJS from 'crypto-js';
import { ipcRenderer } from './components/electronHelper';
import { toast } from 'react-toastify';
import { clearAuthLocalStorage } from './components/localStorageHelper';
import DownloadDialog from './DownloadDialog';

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const secret = "74be738c-088d-454f-8cc9-11ec35c5d414";

  useEffect(() => {
    const proceedWithApp = async () => {
      if (isInElectron()) {
        const isVersionValid = await checkAppVersion();
        if (!isVersionValid) {
          setDialogOpen(true);
          return;
        }
      }

      handleAutoLogin();
    };

    proceedWithApp();
  }, []);

  const handleAutoLogin = () => {
    const token = localStorage.getItem('token');
    if (token && localStorage.getItem('email') && localStorage.getItem('password')) {
      if (isInElectron()) {
        ipcRenderer.send('set-auth-token', token);
      }

      navigate('/articleList');
    }

    const savedEmail = localStorage.getItem('email');
    const savedPassword = localStorage.getItem('password');
    if (savedEmail && savedPassword) {
      const decryptedPassword = CryptoJS.AES.decrypt(savedPassword, secret).toString(CryptoJS.enc.Utf8);
      setEmail(savedEmail);
      setPassword(decryptedPassword);
      setRememberMe(true);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${config.BASE_URL}/user/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });

      const data = await parseResponse(response);

      if (!response.ok) {
        console.error('登录失败:', data);
        throw new Error(`${data}`);
      }

      console.log('登录成功:', data.token);
      localStorage.setItem('token', data.token); // 将token保存在LocalStorage中
      // Send token to electron
      if (isInElectron() && data.token) {
        console.log("Sending token to electron...");
        ipcRenderer.send('set-auth-token', data.token);
      }

      console.log("Remember me is:", rememberMe);
      if (rememberMe) {
        const encryptedPassword = CryptoJS.AES.encrypt(password, secret).toString();
        localStorage.setItem('email', email);
        localStorage.setItem('password', encryptedPassword);
      } else {
        console.log("正在清除存储的邮箱和密码...");
        clearAuthLocalStorage();
      }

      // 根据需要处理登录成功后的逻辑，例如保存登录状态、跳转到主页等
      navigate('/articleList');
    } catch (error) {
      toast.error(`登录失败: ${error.message}`);
      console.error('登录错误:', error);
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
    handleAutoLogin();
  };

  const handleJump = () => {
    if (isInElectron()) {
      window.open('https://flamegull.com', '_blank');
    }

    handleClose();
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <DownloadDialog dialogOpen={dialogOpen} handleClose={handleClose} handleJump={handleJump} />
        <Typography component="h1" variant="h5">
          登录
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="电子邮件地址"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="密码"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                value={rememberMe}
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                color="primary"
              />}
            label="记住我"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            登录
          </Button>
          <Link to="/register" style={{ textDecoration: 'none' }}>
            <Button fullWidth variant="text">{"没有账号？注册"}</Button>
          </Link>
        </Box>
      </Box>
    </Container>
  );
}

export default Login;
