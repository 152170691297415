import React, { useEffect, useState } from 'react';
import { parseResponse, customFetch } from './components/http';
import config from './config';
import {
  Button, TextField, Typography, Grid, Container, Dialog, DialogTitle, DialogContent, DialogActions,
  Checkbox, FormControlLabel, Radio, RadioGroup, FormControl, FormLabel
} from '@mui/material';
import { toast } from 'react-toastify';

function Setting() {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [defaultSettingIndex, setDefaultSettingIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [currentSetting, setCurrentSetting] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = `${config.BASE_URL}/user/user`;
      try {
        const response = await customFetch(apiUrl, { method: 'GET' });
        const result = await parseResponse(response);
        setData(result);
        setDefaultSettingIndex(result.defaultSettingIndex);
      } catch (error) {
        console.error('Error fetching user data: ', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const addNewSetting = () => {
    const newSetting = {
      author: '',
      settingName: '新配置',
      header: { imageUrl: '', text: '', bold: false, center: false, italic: false },
      beforeSection1: { imageUrl: '', text: '', bold: false, center: false, italic: false },
      beforeSection2: { imageUrl: '', text: '', bold: false, center: false, italic: false },
      beforeSection3: { imageUrl: '', text: '', bold: false, center: false, italic: false },
      beforeSection4: { imageUrl: '', text: '', bold: false, center: false, italic: false },
      beforeSection5: { imageUrl: '', text: '', bold: false, center: false, italic: false },
      beforeSection6: { imageUrl: '', text: '', bold: false, center: false, italic: false },
      footer: { imageUrl: '', text: '', bold: false, center: false, italic: false },
      removeSubtitle: false,
    };
    const updatedData = { ...data, settings: [...(data.settings || []), newSetting] };
    setData(updatedData);
  };

  const handleInputChange = (field, value) => {
    setCurrentSetting({ ...currentSetting, [field]: value });
  };

  const handleSectionChange = (section, field, value) => {
    setCurrentSetting({
      ...currentSetting,
      [section]: { ...currentSetting[section], [field]: value }
    });
  };

  const handleFileUpload = async (section, event) => {
    const fileInput = event.target;
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await customFetch(`${config.BASE_URL}/file/upload`, {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`Server responded with status: ${response.status}`);
        }

        const result = await parseResponse(response);
        const imageUrl = result;

        setCurrentSetting({
          ...currentSetting,
          [section]: { ...currentSetting[section], imageUrl }
        });
      } catch (error) {
        console.error('Error during file upload:', error);
        toast.error(`文件上传失败: ${error.message}`);
        fileInput.value = '';
      }
    }
  };

  const handleSubmit = async (updatedSettings, defaultIndex) => {
    const isAuthorEmpty = updatedSettings.some(setting => !setting.author.trim());
    if (isAuthorEmpty) {
      toast.error('作者不能为空！');
      return;
    }

    const apiUrl = `${config.BASE_URL}/user/updateSettings`;
    try {
      const response = await customFetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ settings: updatedSettings, settingIndex: defaultIndex }),
      });

      if (!response.ok) {
        const errorData = await parseResponse(response);
        throw new Error(errorData);
      }

      const result = await parseResponse(response);
      toast.success('上传模板成功！');
      setData({ ...data, settings: updatedSettings });
      setOpen(false);
    } catch (error) {
      console.error('Error submitting settings: ', error);
      toast.error(`上传模板失败：${error.message}`);
    }
  };

  const handleOpen = (index) => {
    setCurrentIndex(index);
    setCurrentSetting({ ...data.settings[index] });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDefaultSettingChange = (index) => {
    setDefaultSettingIndex(index);
    const updatedSettings = [...data.settings];
    setData({ ...data, settings: updatedSettings });
    handleSubmit(updatedSettings, index);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!data) {
    return <div>No data available</div>;
  }

  return (
    <Container>
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid item>
          <Button variant="contained" color="primary" onClick={addNewSetting}>
            增加新模板
          </Button>
        </Grid>
        <Grid item>
          <table style={{ width: '100%', textAlign: 'left' }}>
            <thead>
              <tr>
                <th>模板名称</th>
                <th>作者</th>
                <th>操作</th>
                <th>默认模板</th>
              </tr>
            </thead>
            <tbody>
              {data.settings?.map((setting, index) => (
                <tr key={index}>
                  <td>{setting.settingName}</td>
                  <td>{setting.author}</td>
                  <td>
                    <Button onClick={() => handleOpen(index)}>编辑</Button>
                    <Button onClick={async () => {
                      if (window.confirm("确定要删除吗？")) {
                        const updatedSettings = [...data.settings];
                        updatedSettings.splice(index, 1);
                        setData({ ...data, settings: updatedSettings });
                        await handleSubmit(updatedSettings, defaultSettingIndex);
                      }
                    }}>删除</Button>
                  </td>
                  <td>
                    <Radio
                      checked={defaultSettingIndex === index}
                      onChange={() => handleDefaultSettingChange(index)}
                      value={index}
                      name="default-setting"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>编辑模板</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="模板名称"
            value={currentSetting?.settingName || ''}
            onChange={(e) => handleInputChange('settingName', e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            label="作者"
            value={currentSetting?.author || ''}
            onChange={(e) => handleInputChange('author', e.target.value)}
            margin="normal"
          />
          <Typography variant="h6" component="div" gutterBottom>
            开头固定内容
          </Typography>
          {currentSetting?.header?.imageUrl && (
            <img
              src={currentSetting.header.imageUrl}
              alt="Header"
              style={{ width: '100px' }}
            />
          )}
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleFileUpload('header', e)}
          />
          <TextField
            fullWidth
            multiline
            placeholder="Header Text"
            value={currentSetting?.header?.text || ''}
            onChange={(e) => handleSectionChange('header', 'text', e.target.value)}
            margin="normal"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(currentSetting?.header?.bold)}
                onChange={(e) => handleSectionChange('header', 'bold', e.target.checked)}
              />
            }
            label="加粗"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(currentSetting?.header?.center)}
                onChange={(e) => handleSectionChange('header', 'center', e.target.checked)}
              />
            }
            label="居中"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(currentSetting?.header?.italic)}
                onChange={(e) => handleSectionChange('header', 'italic', e.target.checked)}
              />
            }
            label="斜体"
          />
          {[...Array(6)].map((_, i) => (
            <div key={i}>
              <Typography variant="h6" component="div" gutterBottom>
                Section {i + 1}
              </Typography>
              {currentSetting?.[`beforeSection${i + 1}`]?.imageUrl && (
                <img
                  src={currentSetting[`beforeSection${i + 1}`].imageUrl}
                  alt={`Section ${i + 1}`}
                  style={{ width: '100px' }}
                />
              )}
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleFileUpload(`beforeSection${i + 1}`, e)}
              />
              <TextField
                fullWidth
                multiline
                placeholder={`Section ${i + 1} Text`}
                value={currentSetting?.[`beforeSection${i + 1}`]?.text || ''}
                onChange={(e) => handleSectionChange(`beforeSection${i + 1}`, 'text', e.target.value)}
                margin="normal"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(currentSetting?.[`beforeSection${i + 1}`]?.bold)}
                    onChange={(e) => handleSectionChange(`beforeSection${i + 1}`, 'bold', e.target.checked)}
                  />
                }
                label="加粗"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(currentSetting?.[`beforeSection${i + 1}`]?.center)}
                    onChange={(e) => handleSectionChange(`beforeSection${i + 1}`, 'center', e.target.checked)}
                  />
                }
                label="居中"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(currentSetting?.[`beforeSection${i + 1}`]?.italic)}
                    onChange={(e) => handleSectionChange(`beforeSection${i + 1}`, 'italic', e.target.checked)}
                  />
                }
                label="斜体"
              />
            </div>
          ))}
          <Typography variant="h6" component="div" gutterBottom>
            结尾固定内容
          </Typography>
          {currentSetting?.footer?.imageUrl && (
            <img
              src={currentSetting.footer.imageUrl}
              alt="Footer"
              style={{ width: '100px' }}
            />
          )}
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleFileUpload('footer', e)}
          />
          <TextField
            fullWidth
            multiline
            placeholder="Footer Text"
            value={currentSetting?.footer?.text || ''}
            onChange={(e) => handleSectionChange('footer', 'text', e.target.value)}
            margin="normal"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(currentSetting?.footer?.bold)}
                onChange={(e) => handleSectionChange('footer', 'bold', e.target.checked)}
              />
            }
            label="加粗"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(currentSetting?.footer?.center)}
                onChange={(e) => handleSectionChange('footer', 'center', e.target.checked)}
              />
            }
            label="居中"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(currentSetting?.footer?.italic)}
                onChange={(e) => handleSectionChange('footer', 'italic', e.target.checked)}
              />
            }
            label="斜体"
          />

        </DialogContent>
        <DialogContent>
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(currentSetting?.removeSubtitle)}
                onChange={(e) => handleInputChange('removeSubtitle', e.target.checked)}
              />
            }
            label="删除小标题"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              const updatedSettings = [...data.settings];
              updatedSettings[currentIndex] = currentSetting;
              setData({ ...data, settings: updatedSettings });
              await handleSubmit(updatedSettings, defaultSettingIndex);
            }}
            color="primary"
          >
            保存
          </Button>
          <Button onClick={handleClose} color="secondary">
            取消
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Setting;
