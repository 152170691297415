import React, { useState } from 'react';
import { Dialog, AppBar, Toolbar, IconButton, Typography, Box, TextField, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Slide } from '@mui/material';
import { handleUris } from './components/shared';
import { parseResponse, customFetch } from './components/http';
import config from './config';
import { toast } from 'react-toastify';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ChatDialog({ open, handleClose, messages, messageText, setMessageText, setMessages, articleId, setDialogOpen, language, navigate, filePath }) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleMessageChange = (event) => {
    setMessageText(event.target.value);
  };

  const determineTopic = async () => {
    try {
      var apiUrl = `${config.BASE_URL}/article/topic`;

      const formData = new FormData();
      formData.append('articleId', articleId);
      formData.append('language', language);
      formData.append('message', messageText.trim());

      const response = await customFetch(apiUrl, {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        const res = await parseResponse(response);
        if (res && res.articleId && Array.isArray(res.uris) && res.uris.length > 0) {
          await handleUris(res.articleId, res.uris, filePath, navigate);

          setDialogOpen(false);
          navigate('/articleList');
        } else if (res && res.message) {
          setDialogOpen(true);
          const replyMessage = { text: res.message, fromUser: false };
          setMessages(messages => [...messages, replyMessage]);
        } else {
          // Handle other cases if needed
          navigate('/articleList');
        }
      } else {
        const errorData = await parseResponse(response);
        console.error("发送请求失败:", errorData);
        throw new Error("发送请求失败:" + errorData);
      }
    } catch (error) {
      console.error('There was a problem with your fetch operation:', error);
      toast.error(`发生了一点小问题: ${error.message || '未知错误'}`);
    } finally {
      setIsButtonDisabled(false); // 在请求完成后重置按钮状态
    }
  }

  const sendMessage = async () => {
    if (messageText.trim() === '') return;
    setIsButtonDisabled(true); // 发送消息时禁用按钮
    const newMessage = { text: messageText, fromUser: true };
    setMessages([...messages, newMessage]);
    await determineTopic();
    setMessageText('');
  };

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            请确定您的主题
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 2, height: 'calc(100vh - 64px)', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ flexGrow: 1, overflow: 'auto', p: 2 }}>
          {messages.map((msg, index) => (
            <Box key={index} sx={{
              display: 'flex', // 使用flex布局
              justifyContent: msg.fromUser ? 'flex-end' : 'flex-start', // 根据消息来源决定对齐方式
              p: 1, // 外部间距
            }}>
              <Box sx={{
                maxWidth: '70%', // 限制最大宽度
                bgcolor: msg.fromUser ? '#4caf50' : '#424242', // 用户消息背景色为深绿色，其他消息背景色为深灰色
                color: msg.fromUser ? '#2E2E2E' : '#D3D3D3', // 用户消息字体颜色为深灰色，其他消息字体颜色为浅灰色
                borderRadius: '16px', // 圆角
                padding: '8px 16px', // 内部间距
                textAlign: msg.fromUser ? 'right' : 'left', // 文本对齐方式
                wordBreak: 'break-word', // 防止长单词/URL溢出
                width: 'fit-content', // 宽度根据内容调整
                fontFamily: 'Microsoft YaHei', // 使用微软雅黑字体
              }}>
                {msg.text}
              </Box>
            </Box>
          ))}
        </Box>
        <Box sx={{ display: 'flex', mt: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="输入消息..."
            value={messageText}
            onChange={handleMessageChange}
            onKeyPress={event => event.key === 'Enter' && !isButtonDisabled && sendMessage()}
            sx={{ mr: 1 }}
          />
          <Button variant="contained" onClick={sendMessage} disabled={isButtonDisabled}>
            发送
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default ChatDialog;