import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { parseResponse, customFetch } from './components/http';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Box, Typography, Container, useTheme } from '@mui/material';
import showdown from 'showdown';
import config from './config';
import { toast } from 'react-toastify';

// 组件外部的样式对象
const commonStyles = {
  TextField: { m: 1 },
  Button: { mt: 1, ml: 1 }
};

const converter = new showdown.Converter();
function Rewrite() {
  const theme = useTheme();
  const [formData, setFormData] = useState({
    title: '',
    details: '',
    language: 'SimplifiedChinese',
    file: null,
    fileName: '',
    sampleFile: null,
    sampleFileName: '',
    styleOption: 'Default',
    articleType: 'Default',
  });
  const [htmlContent, setHtmlContent] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (event, fileKey, nameKey) => {
    const file = event.target.files[0];
    if (file) {
      setFormData(prev => ({ ...prev, [fileKey]: file, [nameKey]: file.name }));
    }
  };

  const handleSubmit = async () => {
    setIsButtonDisabled(true);
    const apiUrl = `${config.BASE_URL}/article/rewrite`;
    const { title, details, language, file, sampleFile, styleOption, articleType } = formData;
    const formDataObj = new FormData();
    formDataObj.append('title', title);
    formDataObj.append('details', details);
    formDataObj.append('language', language);
    formDataObj.append('articleStyle', styleOption);
    formDataObj.append('articleType', articleType);
    appendFileToFormData(formDataObj, 'file', file);
    appendFileToFormData(formDataObj, 'sampleFile', sampleFile);

    try {
      const response = await customFetch(apiUrl, {
        method: 'POST',
        body: formDataObj,
      });

      if (response.ok) {
        const data = await parseResponse(response); // 使用parseResponse处理响应
        const markdownHtml = converter.makeHtml(data);
        setHtmlContent(markdownHtml); // 假设setHtmlContent是一个状态设置函数，用于存储HTML内容
      } else {
        const errorData = await parseResponse(response);
        console.error("发送请求失败:", errorData);
        setHtmlContent(`<p>Error: ${errorData}</p>`);
      }
    } catch (error) {
      console.error('There was a problem with your fetch operation:', error);
      toast.error(`发生了一点小问题: ${error.message || '未知错误'}`);
    } finally {
      setIsButtonDisabled(false);
    }
  };

  return (
    <Container maxWidth={false} sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    }}>
      <Box sx={{
        backgroundColor: theme.palette.background.paper,
        p: 2,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[3],
        width: '100%',
        margin: 'auto',
      }}>
        <TextField
          fullWidth
          name="title"
          label="Title"
          value={formData.title}
          onChange={handleInputChange}
          sx={commonStyles.TextField}
        />
        <TextField
          fullWidth
          name="details"
          label="Details"
          value={formData.details}
          onChange={handleInputChange}
          sx={commonStyles.TextField}
        />
        <FormControl fullWidth sx={commonStyles.TextField}>
          <InputLabel>Language</InputLabel>
          <Select
            name="language"
            value={formData.language}
            label="Language"
            onChange={handleInputChange}
          // 使用MUI组件并定义选择项
          >
            <MenuItem value="SimplifiedChinese">Simplified Chinese</MenuItem>
            <MenuItem value="English">English</MenuItem>
            {/* 更多语言选项 */}
          </Select>
        </FormControl>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
          <Button
            variant="contained"
            component="label"
            sx={{ mr: 1 }}
          >
            Upload File
            <input
              type="file"
              hidden
              onChange={(e) => handleFileChange(e, 'file', 'fileName')}
            />
          </Button>
          {formData.fileName && <Typography variant="caption">{formData.fileName}</Typography>}
        </Box>
        <FormControl fullWidth sx={commonStyles.TextField}>
          <InputLabel>Article Type</InputLabel>
          <Select
            name="articleType"
            value={formData.articleType}
            label="Article Type"
            onChange={handleInputChange}
          >
            <MenuItem value="Default">Default</MenuItem>
            <MenuItem value="WashScript">WashScript</MenuItem>
            <MenuItem value="Viewpoint">Viewpoint</MenuItem>
            <MenuItem value="Interpretation">Interpretation</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth sx={commonStyles.TextField}>
          <InputLabel>Article Style</InputLabel>
          <Select
            name="styleOption"
            value={formData.styleOption}
            label="Article Style"
            onChange={handleInputChange}
          >
            <MenuItem value="Default">Default</MenuItem>
            <MenuItem value="Custom">Custom</MenuItem>
          </Select>
        </FormControl>
        {formData.styleOption === 'Custom' && (
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
            <Button
              variant="contained"
              component="label"
              sx={{ mr: 1 }}
            >
              Upload Sample File
              <input
                type="file"
                hidden
                onChange={(e) => handleFileChange(e, 'sampleFile', 'sampleFileName')}
              />
            </Button>
            {formData.sampleFileName && <Typography variant="caption">{formData.sampleFileName}</Typography>}
          </Box>
        )}
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={isButtonDisabled} // 根据状态变量来禁用按钮
          sx={{ mt: 2, bgcolor: isButtonDisabled ? 'grey.500' : 'primary.main' }} // 根据状态变量改变按钮颜色
        >
          Send Request
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <div className="markdown-body" dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </Box>
    </Container>
  );
}

function appendFileToFormData(formData, key, file) {
  if (file) {
    formData.append(key, file);
  } else {
    const emptyFile = new Blob([], { type: 'application/octet-stream' });
    formData.append(key, emptyFile, 'empty.txt');
  }
}

export default Rewrite;
