import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Alert, AlertTitle } from '@mui/material';

const DownloadDialog = ({ dialogOpen, handleClose, handleJump }) => {
  const handleLinkClick = (event) => {
    event.preventDefault(); // 阻止默认的跳转行为
    handleJump(); // 调用跳转函数
  };

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          border: '1px solid #f44336', // 红色边框
          boxShadow: '0px 0px 20px rgba(244, 67, 54, 0.7)', // 红色阴影
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <Alert severity="error">
          <AlertTitle>版本太低</AlertTitle>
        </Alert>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <strong>警告：</strong>您的版本太低，可能无法正常使用应用。请访问 <a href="#" onClick={handleLinkClick} style={{ color: '#f44336', fontWeight: 'bold', textDecoration: 'none' }}>https://flamegull.com</a> 下载适合您的版本。
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="outlined">
          关闭
        </Button>
        <Button onClick={handleJump} color="primary" variant="contained" style={{ backgroundColor: '#f44336' }}>
          跳转
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DownloadDialog;
