import React, { useState, useEffect } from 'react';
import { Container, Grid, Card, CardContent, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Modal, Box, CardActions } from '@mui/material';
import { parseResponse, customFetch } from './components/http';
import config from './config';
import { fetchUserData } from './components/user.js';
import { toast } from 'react-toastify';

const OrderTable = ({ orderList }) => {
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleString('zh-CN', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  };

  const paidOrders = orderList.filter(order => order.status === 'Paid');

  return (
    <TableContainer component={Paper} style={{ marginTop: '20px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>订单号</TableCell>
            <TableCell>时间</TableCell>
            <TableCell>充值火种数量</TableCell>
            <TableCell>支付金额</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paidOrders.map((order) => (
            <TableRow key={order.tradeNo}>
              <TableCell>{order.tradeNo}</TableCell>
              <TableCell>{order.completedTime ? formatDate(order.completedTime) : ''}</TableCell>
              <TableCell>{order.productModel.point}</TableCell>
              <TableCell>{order.productModel.price}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Pay() {
  const [products, setProducts] = useState([]);
  const [qrCode, setQrCode] = useState(null);
  const [modalOpen, setModalOpen] = useState(false); // used for wx pay
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [user, setUser] = useState(null);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    fetchProducts();
    fetchUserData(setUser);
  }, []);

  useEffect(() => {
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);

  const fetchProducts = async () => {
    const apiUrl = `${config.BASE_URL}/tenpayv3/products`;
    try {
      const response = await customFetch(apiUrl, { method: 'GET' });
      if (!response.ok) {
        const errorData = await parseResponse(response);
        console.error('Sending request failed:', errorData);
        throw new Error('Sending request failed:' + errorData);
      }

      const result = await response.json();
      setProducts(result); // 更新产品数据
      console.log("products:", result);
    } catch (error) {
      console.error('Error during request:', error);
    }
  };

  const checkOrderStatus = async (orderId) => {
    if (!orderId) {
      return;
    }

    try {
      const url = new URL(`${config.BASE_URL}/user/order`);
      url.searchParams.append('orderId', orderId); // 正确添加查询字符串参数

      const response = await customFetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await parseResponse(response);
      console.log(data)
      if (data.status === 'Paid') {
        console.log("支付已完成");
        clearInterval(intervalId);
        setIntervalId(null);
        toast.success('恭喜您！充值成功！');
        fetchUserData(setUser);
        setModalOpen(false);
      }
    } catch (error) {
      console.error('查询订单状态失败:', error);
    }
  };

  const handlePayment = async (product) => {
    // 处理支付逻辑
    console.log(`支付产品: ${product.name}`);
    try {
      const response = await customFetch(`${config.BASE_URL}/tenpayv3/nativepay`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: product.id
      });
      const data = await parseResponse(response);
      if (!response.ok) {
        console.error('生成支付码失败:', data);
        throw new Error(`生成支付码失败: ${data}`);
      }

      setSelectedProduct(product);
      console.log(`订单编号: ${data.orderId}`);
      setQrCode(`data:image/png;base64,${data.qrCode}`);
      setModalOpen(true); // Open modal on successful QR code generation
      console.log("生成支付码成功", data);

      if (intervalId) {
        // 清除现有的定时器
        clearInterval(intervalId);
      }
      const newIntervalId = setInterval(() => checkOrderStatus(data.orderId), 5000); // 每5秒检查一次订单状态
      setIntervalId(newIntervalId);
    } catch (error) {
      console.error('生成支付码失败:', error);
    }
  };

  const handleClose = () => {
    clearInterval(intervalId); // 关闭定时器
    setIntervalId(null);
    setModalOpen(false);
  };

  return (
    <Container>
      {user && (
        <div>
          <Typography variant="h6">当前剩余火种: {user.point}</Typography>
        </div>
      )}
      <Grid container spacing={3}>
        {products.map((product) => (
          <Grid item xs={12} sm={6} md={3} key={product.id}>
            <Card sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
              <CardContent>
                <Typography variant="h5" component="div" noWrap>
                  {product.name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {product.point}颗
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {product.price}元
                </Typography>
              </CardContent>
              <CardActions sx={{ mt: 'auto' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handlePayment(product)}
                  fullWidth
                >
                  支付
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            微信支付
          </Typography>
          <Typography variant="subtitle1">
            应付金额: {selectedProduct ? `${selectedProduct.price}元` : '加载中...'}
          </Typography>
          {qrCode && <img src={qrCode} alt="QR Code" style={{ width: '100%', height: 'auto' }} />}
          <Button onClick={handleClose} style={{ marginTop: '20px' }}>
            关闭
          </Button>
        </Box>
      </Modal>
      {user && user.orderList && (
        <OrderTable orderList={user.orderList} />
      )}
    </Container>
  );
}

export default Pay;
