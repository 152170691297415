// components/PublicAccountModal.js
import React, { useState } from 'react';
import { Modal, Box, Typography, Select, MenuItem, Button } from '@mui/material';
import { customFetch, parseResponse } from './http';
import config from '../config';
import { toast } from 'react-toastify';

const PublicAccountModal = ({
  open,
  onClose,
  user,
  articleId,
}) => {
  const [selectedAccount, setSelectedAccount] = useState('');

  const handleAccountChange = (event) => {
    setSelectedAccount(event.target.value);
  };

  const handleSyncToAccount = async () => {
    try {
      const apiUrl = `${config.PUBLISH_URL}/article/publicaccount`;
      const formData = new FormData();
      if (!selectedAccount) {
        throw new Error('必须选择一个公众号！');
      } 

      formData.append('articleId', articleId);
      formData.append('publicAccountAppId', selectedAccount);

      const response = await customFetch(apiUrl, {
        method: 'POST',
        body: formData,
      }, true);

      if (response.ok) {
        toast.success("文章已成功同步到公众号！");
        onClose();
      } else {
        const errorData = await parseResponse(response);
        console.error("同步到公众号失败:", errorData);
        throw new Error("同步到公众号失败:" + errorData);
      }
    } catch (error) {
      console.error('Error sending article to account:', error);
      toast.error(`发生了一点小问题: ${error.message || '未知错误'}`);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', p: 4, boxShadow: 24 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>选择要发送的公众号</Typography>
        <Select
          fullWidth
          value={selectedAccount}
          onChange={handleAccountChange}
          displayEmpty
          sx={{ mb: 2 }}
        >
          <MenuItem value="" disabled>选择公众号</MenuItem>
          {user?.publicAccounts?.map((account) => (
            <MenuItem key={account.appId} value={account.appId}>{account.nickname}</MenuItem>
          ))}
        </Select>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" onClick={handleSyncToAccount}>同步</Button>
          <Button variant="outlined" onClick={onClose}>取消</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default PublicAccountModal;
