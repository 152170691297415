import { toast } from 'react-toastify';

export const customFetch = async (url, options = {}, showLoading = false) => {
  // 从LocalStorage获取token
  const token = localStorage.getItem('token');

  // 如果存在token，则在请求头中添加Authorization字段
  if (token) {
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    };
  }

  let toastId;
  if (showLoading) {
    toastId = toast.loading('加载中，请勿离开...');
  }

  try {
    const response = await fetch(url, options);

    // 检查token是否过期或无效
    if (response.status === 401) {
      console.error('Token过期或无效');
      // 清除过期或无效的token
      localStorage.removeItem('token');
      // 重定向到登录页面
      // TODO: 请注意：这里需要额外处理，因为在非组件文件中使用 `navigate` 需要额外的逻辑
      toast.error('登录已过期，请重新登录！');
      window.location.href = '#/login';
    }

    return response;
  } catch (error) {
    console.error('请求失败:', error);
    toast.error('请求失败，请稍后再试！');
  } finally {
    if (showLoading) {
      toast.dismiss(toastId);
    }
  }
};

export const parseResponse = async (response) => {
  const contentType = response.headers.get('Content-Type');

  if (contentType && contentType.includes('application/json')) {
    // 如果是JSON格式，正常解析JSON
    return await response.json();
  } else if (contentType && contentType.includes('image/png')) {
    // 如果是PNG格式，返回Blob
    return await response.blob();
  } else {
    // 如果不是JSON格式，可能是纯文本，直接读取文本
    return await response.text();
  }
};

