// shared.js
import { fetchUrlContent } from './scraper';
import { isInElectron, requestSeleniumTaskInElectron } from './electronHelper';
import config from '../config';
import { customFetch, parseResponse } from './http';
import { toast } from 'react-toastify';

// 读取文件内容
export function readFileContent(file) {
  return new Promise((resolve, reject) => {
    if (!file) {
      resolve('');
      return;
    }

    const reader = new FileReader();
    reader.onload = function (event) {
      resolve(event.target.result);
    };
    reader.onerror = function (event) {
      reject("Error reading file: " + event.target.error.code);
    };
    reader.readAsText(file);
  });
}

// 将 URL 列表附加到 FormData
export function appendUrlsToFormData(urls, formData) {
  const isValidUrl = url => url.trim() !== '';

  urls.forEach((url, index) => {
    if (isValidUrl(url)) {
      formData.append(`textUrls[${index}]`, url);
    }
  });
}

export function appendFormattedTextUrlsToFormData(urls, formData) {
  const isValidUrl = url => url.trim() !== '';

  urls.forEach((url, index) => {
    if (isValidUrl(url)) {
      formData.append(`formattedTextUrls[${index}]`, url);
    }
  });
}

// 根据环境请求 Selenium 任务
export function requestSeleniumTaskPromise(url) {
  if (isInElectron()) {
    return requestSeleniumTaskInElectron(url);
  } else {
    // 非 Electron 环境的逻辑，比如直接抓取内容
    return fetchUrlContent(url);
  }
}

// 创建内容 Blob
export async function createContentBlob(filePath, urls) {
  try {
    // 读取文件内容
    const fileContent = await readFileContent(filePath);
    // 根据环境请求 URL 内容
    if (isInElectron()) {
      const tasks = urls.map(url => requestSeleniumTaskPromise(url));
      const results = await Promise.all(tasks);

      console.log("Content from url:", results);
      // 组合文件和 URL 内容
      const urlsContent = results.flatMap(result => result.content || '');
      const finalContent = fileContent + '\n' + urlsContent.join('\n');

      const allImages = results.reduce((acc, result) => {
        return acc.concat(result.images); // 将当前 result 的 images 数组合并到累积的数组中
      }, []);

      const uniqueImages = Array.from(new Set(allImages));
      return { filelob: new Blob([finalContent.trim()], { type: 'text/plain' }), images: uniqueImages };
    }

    return { filelob: new Blob([fileContent], { type: 'text/plain' }), images: null };
  } catch (error) {
    console.error('Error creating content blob:', error);
    throw error;
  }
}

export async function handleUris(articleId, uris, filePath, navigateCallback, countdown = 30) {
  const toastId = toast.loading(`加载中，请勿离开... (${countdown}s)`);
  let intervalCleared = false;

  // 倒计时逻辑
  let intervalId = setInterval(() => {
    countdown -= 1;
    if (countdown > 0) {
      toast.update(toastId, {
        render: `加载中，请勿离开... (${countdown}s)`,
      });
    } else {
      clearInterval(intervalId);
      intervalCleared = true;
      toast.update(toastId, {
        render: '加载中，请勿离开...',
      });
    }
  }, 1000);

  try {
    var apiUrl = `${config.BASE_URL}/article/generate`;
    const formData = new FormData();
    formData.append('topic', "fake"); // TODO: fake
    formData.append('articleId', articleId);
    const { filelob, images } = await createContentBlob(filePath, uris);
    formData.append('file', filelob, 'file.txt');
    if (images) {
      images.forEach((url, index) => {
        if (url.trim() !== '') {
          formData.append(`imageUrls[${index}]`, url);
        }
      });
    }

    if (!isInElectron()) {
      // 适配浏览器端
      appendUrlsToFormData(uris, formData);
      formData.append('IsInElectron', false);
    } else {
      formData.append('IsInElectron', true);
    }

    // Do not need show toast loading in customFetch, because it is handled in this function
    const response = await customFetch(apiUrl, {
      method: 'POST',
      body: formData
    });

    if (response.ok) {
      if (!intervalCleared) {
        clearInterval(intervalId); // 停止倒计时
      }

      if (navigateCallback) {
        toast.success("文章生成中，请稍后查看！");
        navigateCallback('/articleList');
      }
    } else {
      const errorData = await parseResponse(response);
      console.error("发送请求失败:", errorData);
      throw new Error("发送请求失败:" + errorData);
    }
  } catch (error) {
    console.error('There was a problem with your fetch operation:', error);
    toast.error(`发生了一点小问题: ${error.message || '未知错误'}`);
  } finally {
    if (!intervalCleared) {
      clearInterval(intervalId); // 确保在 finally 块中清理计时器
    }
    toast.dismiss(toastId);
  }
}