import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Button, TextField, IconButton, Modal, Select, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import TextareaAutosize from 'react-textarea-autosize';
import { customFetch, parseResponse } from './components/http';
import { handleUris, readFileContent, createContentBlob, appendUrlsToFormData } from './components/shared';
import { ipcRenderer, isInElectron, requestSeleniumTaskInElectron } from './components/electronHelper';
import config from './config';
import showdown from 'showdown';
import 'github-markdown-css/github-markdown.css';
import PublicAccountModal from './components/PublicAccountModal';
import { fetchUserData } from './components/user.js';
import { toast } from 'react-toastify';

const converter = new showdown.Converter();
const EditableOutlineForm = ({ outline, selected, onClick, index, onOutlineChange }) => {
  const [formOutline, setFormOutline] = useState(outline);

  // 修改章节的 subtitle
  const handleChange = (sectionIndex, field, value) => {
    const newOutline = [...formOutline];
    newOutline[sectionIndex][field] = value;
    setFormOutline(newOutline);
    onOutlineChange(newOutline); // 通知父组件更新 outline
  };

  // 修改详细信息
  const handleDetailChange = (sectionIndex, detailIndex, value) => {
    const newOutline = [...formOutline];
    newOutline[sectionIndex].details[detailIndex] = value;
    setFormOutline(newOutline);
    onOutlineChange(newOutline); // 通知父组件更新 outline
  };

  // 删除某个详细信息
  const handleDetailDelete = (sectionIndex, detailIndex) => {
    const newOutline = [...formOutline];
    if (newOutline[sectionIndex].details.length === 1) {
      toast.error("小标题下必须要包含内容！");
      return;
    }

    newOutline[sectionIndex].details.splice(detailIndex, 1);
    setFormOutline(newOutline);
    onOutlineChange(newOutline); // 通知父组件更新 outline
  };

  // 删除某个章节
  const handleSectionDelete = (sectionIndex) => {
    const newOutline = [...formOutline];
    if (newOutline.length === 1) {
      toast.error("大纲里至少要有一个小标题！");
      return;
    }
    newOutline.splice(sectionIndex, 1);
    setFormOutline(newOutline);
    onOutlineChange(newOutline); // 通知父组件更新 outline
  };

  // 添加章节
  const handleSectionAdd = (sectionIndex) => {
    const newOutline = [...formOutline];
    newOutline.splice(sectionIndex + 1, 0, { subtitle: '', details: [''] });
    setFormOutline(newOutline);
    onOutlineChange(newOutline); // 通知父组件更新 outline
  };

  // 添加详细信息
  const handleDetailAdd = (sectionIndex, detailIndex) => {
    const newOutline = [...formOutline];
    newOutline[sectionIndex].details.splice(detailIndex + 1, 0, '');
    setFormOutline(newOutline);
    onOutlineChange(newOutline); // 通知父组件更新 outline
  };

  return (
    <Box
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        border: selected ? '2px solid blue' : '1px solid #ccc',
        borderRadius: 2,
        p: 2,
        flex: 1,
      }}
    >
      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
        {`大纲 ${index + 1}`}
      </Typography>
      {formOutline.map((section, index) => (
        <Box key={index} sx={{ mb: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h7" sx={{ fontWeight: 'bold', mb: 0, fontSize: '1rem' }}>
              {`小标题 ${index + 1}`}
            </Typography>
            <Box>
              <IconButton onClick={() => handleSectionAdd(index)} sx={{ padding: '2px' }}>
                <AddIcon sx={{ fontSize: '0.75rem' }} />
              </IconButton>
              <IconButton onClick={() => handleSectionDelete(index)} sx={{ padding: '2px' }}>
                <RemoveIcon sx={{ fontSize: '0.75rem' }} />
              </IconButton>
            </Box>
          </Box>
          <TextField
            fullWidth
            value={section.subtitle}
            onChange={(e) => handleChange(index, 'subtitle', e.target.value)}
            sx={{ input: { fontWeight: 'bold' } }}
          />
          <Box sx={{ pl: 3 }}>
            {section.details.map((detail, detailIndex) => (
              <Box key={detailIndex} sx={{ mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 0 }}>
                  <Typography variant="subtitle1" sx={{ mr: 1, fontSize: '0.75rem' }}>
                    {`内容 ${index + 1}.${detailIndex + 1}`}
                  </Typography>
                  <Box>
                    <IconButton onClick={() => handleDetailAdd(index, detailIndex)} sx={{ padding: '2px' }}>
                      <AddIcon sx={{ fontSize: '0.75rem' }} />
                    </IconButton>
                    <IconButton onClick={() => handleDetailDelete(index, detailIndex)} sx={{ padding: '2px' }}>
                      <RemoveIcon sx={{ fontSize: '0.75rem' }} />
                    </IconButton>
                  </Box>
                </Box>
                <TextareaAutosize
                  value={detail}
                  onChange={(e) => handleDetailChange(index, detailIndex, e.target.value)}
                  style={{
                    width: '100%',
                    fontSize: '0.75rem',
                    borderRadius: '4px',
                    borderColor: '#ccc',
                    padding: '8px',
                    resize: 'none',
                    overflow: 'hidden',
                    fontFamily: 'Arial, sans-serif',
                  }}
                />
              </Box>
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const Article = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [htmlContent, setHtmlContent] = useState('');
  const [showRetryButton, setShowRetryButton] = useState(false);
  const [outlines, setOutlines] = useState([]);
  const [selectedOutlineIndex, setSelectedOutlineIndex] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [state, setState] = useState('');
  const [user, setUser] = useState(null);
  const [openModal, setOpenModal] = useState(false); // 控制modal的显示
  const [showSendButton, setShowSendButton] = useState(false);
  const [regenerateDisabled, setRegenerateDisabled] = useState(false);

  const handleOutlineChange = (newOutline, index) => {
    const newOutlines = [...outlines];
    newOutlines[index] = newOutline;
    setOutlines(newOutlines);
  };

  const handleOutlineSelection = async () => {
    if (selectedOutlineIndex === null) {
      toast.error('必须选择一个大纲');
      return;
    }

    const selectedOutline = outlines[selectedOutlineIndex];
    console.log('Selected Outline:', selectedOutline);
    // Implement the function to submit the selected outline here
    // Example:
    try {
      const formData = new FormData();
      formData.append('topic', "fake"); // TODO: fake
      formData.append('articleId', id);

      // 遍历 `selectedOutline` 列表并添加表单字段
      selectedOutline.forEach((section, index) => {
        formData.append(`outlineSections[${index}].subtitle`, section.subtitle || '');

        // 添加 `details` 子数组
        section.details.forEach((detail, detailIndex) => {
          formData.append(`outlineSections[${index}].details[${detailIndex}]`, detail);
        });

        // 添加 `content` 字段
        formData.append(`outlineSections[${index}].content`, section.content || '');
      });

      const response = await customFetch(`${config.BASE_URL}/article/generate`, {
        method: 'POST',
        body: formData,
      }, true);

      if (!response.ok) {
        const errorData = await parseResponse(response);
        console.error('Sending outline selection failed:', errorData);
        throw new Error('选大纲失败:' + errorData);
      }
      else {
        const data = await parseResponse(response);
        if (Array.isArray(data)) {
          console.log('Received URIs:', data);
          await handleUris(id, data, "", null);
        } else {
          console.log('No URIs returned');
        }
      }

      toast.success('大纲选择成功，请稍后查看文章！');
      navigate('/articleList');
    } catch (error) {
      console.error('Error submitting outline selection:', error);
      setErrorMessage(`发生了错误: ${error.message}`);
      setShowRetryButton(true);
    }
  };

  const fetchArticle = async () => {
    const apiUrl = `${config.BASE_URL}/article/${id}`;
    try {
      const response = await customFetch(apiUrl, { method: 'GET' }, true);
      if (!response.ok) {
        const errorData = await parseResponse(response);
        console.error('Sending request failed:', errorData);
        throw new Error('Sending request failed:' + errorData);
      }

      const result = await response.json();
      console.log(result);

      setState(result.state);
      setErrorMessage('发生了错误，请重试或关闭文章！');
      // setErrorMessage(result.errorMessage || '');
      if (result.state === 'Completed') {
        const markdownHtml = converter.makeHtml(result.content);
        setHtmlContent(markdownHtml);
        setShowSendButton(true); // 设置状态以"同步到公众号"按钮
      } else if (result.state === 'UrisAdded') {
        if (isInElectron()) {
          // Electron环境下使用Selenium处理
          // 否则由后端来处理
          await handleUris(result.articleId, result.uris, "", navigate);
        } else {
          toast.warn("正在爬取最新信息，请稍后挑选大纲！")
          navigate('/articleList');
        }
      } else if (result.state === 'OutlineSelection') {
        setOutlines(result.availableOutlines);
      } else if (result.state === 'Closed') {
        setHtmlContent('<p>文章已关闭。</p>');
      } else {
        setHtmlContent('<p>文章正在生成中，请稍后查看。</p>');
      }

      const lastRunTime = new Date(result.lastRunTime);
      const now = new Date();
      const diff = now - lastRunTime;
      if (result.state !== 'Closed') {
        if (diff > 30 * 60000 && result.state !== 'Completed') {
          setShowRetryButton(true);
        }

        if (result.errorMessage && result.state !== 'Completed') {
          setHtmlContent(`<p>发生了错误，请重试或关闭文章！</p>`);
          setShowRetryButton(true);
        }
      }
    } catch (error) {
      console.error('Error during request:', error);
      setHtmlContent(`<p>发送了错误: ${error.message}</p>`);
      setShowRetryButton(true);
    }
  };

  // 新增的处理modal显示和关闭的函数
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  useEffect(() => {
    fetchArticle();
  }, [id]);

  useEffect(() => {
    fetchUserData(setUser);
  }, []);

  if (outlines.length > 0) {
    return (
      <Container>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, position: 'fixed', top: '64px', left: '240px', right: 0, backgroundColor: 'white', zIndex: 1000, padding: '16px 16px' }}>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', mr: 1.5 }}>选择大纲</Typography>
          <Typography variant="subtitle1" gutterBottom>您可以在下方三个大纲中进行选择，或结合随意进行修改</Typography>
        </Box>
        <Box sx={{ flex: 1, overflowY: 'auto', mt: '42px', mb: '80px', display: 'flex', justifyContent: 'space-between', gap: 2 }}>
          {outlines.map((outline, index) => (
            <EditableOutlineForm
              key={index}
              outline={outline}
              selected={index === selectedOutlineIndex}
              onClick={() => setSelectedOutlineIndex(index)}
              index={index}
              onOutlineChange={(newOutline) => handleOutlineChange(newOutline, index)}
            />
          ))}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            position: 'fixed',
            bottom: 0,
            left: '240px',
            right: 0,
            backgroundColor: 'white',
            zIndex: 1000,
            padding: '16px 0',
          }}
        >
          <Button
            variant="contained"
            onClick={handleOutlineSelection}
            sx={{ width: '300px', height: '50px', fontSize: '1rem' }}
          >
            下一步
          </Button>
        </Box>
      </Container>
    );
  }

  const handleRetry = async () => {
    // TODO
    setRegenerateDisabled(true);
    console.log("重新生成文章...");
    const apiUrl = `${config.BASE_URL}/article/regenerate`;
    const formData = new FormData();
    formData.append('taskId', id);;
    try {
      const response = await customFetch(apiUrl, {
        method: 'POST',
        body: formData, // 使用FormData对象
      });

      if (response.ok) {
        toast.success("文章重新生成中，请稍后查看！");
        navigate('/articleList');
      } else {
        const errorData = await parseResponse(response);
        console.error("发送请求失败:", errorData);
        throw new Error("发送请求失败:" + errorData);
      }
    } catch (error) {
      console.error('There was a problem with your fetch operation:', error);
      toast.error(`重新生成文章失败：${error.message}`); // 或其他错误处理
    } finally {
      setRegenerateDisabled(false);
    }
  };

  const handleClose = async () => {
    console.log("关闭文章...");
    const apiUrl = `${config.BASE_URL}/article/close`;
    const formData = new FormData();
    formData.append('taskId', id);;
    try {
      const response = await customFetch(apiUrl, {
        method: 'POST',
        body: formData, // 使用FormData对象
      });

      if (response.ok) {
        toast.success("文章关闭成功，点数已返还！");
        navigate('/articleList');
      } else {
        const errorData = await parseResponse(response);
        console.error("发送请求失败:", errorData);
        throw new Error("发送请求失败:" + errorData);
      }
    } catch (error) {
      console.error('There was a problem with your fetch operation:', error);
      toast.error(`关闭文章失败，请稍后重试！错误：${error.message}`); // 或其他错误处理
    }
  };

  return (
    <Container>
      <Box sx={{ mt: 3 }}>
        {htmlContent && (
          <>
            <div className="markdown-body" dangerouslySetInnerHTML={{ __html: htmlContent }} />
            {showSendButton && (
              <Button
                variant="contained"
                onClick={handleOpenModal}
                sx={{ position: 'fixed', top: 72, right: 0, zIndex: 1000 }}
              >
                同步到公众号
              </Button>
            )}
            <PublicAccountModal
              open={openModal}
              onClose={handleCloseModal}
              user={user}
              articleId={id}
            />
          </>
        )}
        {showRetryButton && (
          <>
            <Button variant="contained" onClick={handleRetry} sx={{ mt: 2 }} disabled={regenerateDisabled}>
              很抱歉，出现了点小问题，请点击重新生成一下哦~
            </Button>
            <Button variant="contained" onClick={handleClose} sx={{ mt: 2, ml: 2 }}>
              您也可以选择关闭文章，我们会返还点数给您~
            </Button>
          </>
        )}
      </Box>
    </Container>
  );
};

export default Article;
