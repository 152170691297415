import React, { useEffect, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { HashRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { Typography, IconButton, AppBar, Toolbar, Box, Container, CssBaseline, Drawer, List, ListItem, ListItemText, Snackbar, Alert, Button } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './Login';
import Register from './Register';
import Article from './Article';
import ArticleList from './ArticleList';
import Generate from './Generate';
import Rewrite from './Rewrite';
import Setting from './Setting';
import Pay from './Pay';
import Bind from './Bind';
import BindCallback from './BindCallback';
import OfficialWebsite from './OfficialWebsite';
import { isInElectron, getAppVersion } from './components/electronHelper';

const drawerWidthPercentage = 12;

const DrawerComponent = ({ handleNavigate, drawerOpen }) => (
  <Drawer
    anchor="left"
    variant="persistent"
    open={drawerOpen}
    sx={{
      width: `${drawerWidthPercentage}%`,
      flexShrink: 0,
      [`& .MuiDrawer-paper`]: { width: `${drawerWidthPercentage}%`, boxSizing: 'border-box' },
    }}
  >
    <Toolbar />
    <Box sx={{ overflow: 'auto' }}>
      <List>
        <ListItem button onClick={() => handleNavigate('/generate')}>
          <ListItemText primary="创建文章" />
        </ListItem>
        <ListItem button onClick={() => handleNavigate('/articleList')}>
          <ListItemText primary="文章列表" />
        </ListItem>
        <ListItem button onClick={() => handleNavigate('/setting')}>
          <ListItemText primary="模板设置" />
        </ListItem>
        <ListItem button onClick={() => handleNavigate('/pay')}>
          <ListItemText primary="账户充值" />
        </ListItem>
        <ListItem button onClick={() => handleNavigate('/bind')}>
          <ListItemText primary="绑定公众号" />
        </ListItem>
      </List>
    </Box>
  </Drawer>
);

const AppBarComponent = ({ handleReload, handleHelp, handleLogout, toggleDrawer, version }) => (
  <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
    <Toolbar>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={toggleDrawer}
        sx={{ mr: 2 }}
      >
        <MenuIcon />
      </IconButton>
      <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
        <img src="img/flamegulllogo.png" alt="Logo" style={{ height: '40px', width: 'auto', marginRight: '16px' }} />
        <Typography variant="h6" noWrap>
          火鸥
        </Typography>
      </Box>
      {version && (
        <Typography variant="body2" sx={{ marginRight: '16px' }}>
          客户端版本: {version}
        </Typography>
      )}
      <IconButton color="inherit" onClick={handleReload}>
        <RefreshIcon />
      </IconButton>
      <IconButton color="inherit" onClick={handleHelp}>
        <HelpOutlineIcon />
      </IconButton>
      <IconButton color="inherit" onClick={handleLogout}>
        <ExitToAppIcon />
      </IconButton>
    </Toolbar>
  </AppBar>
);

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [version, setVersion] = useState('');

  useEffect(() => {
    const setAppVersion = async () => {
      if (isInElectron()) {
        const appVersion = await getAppVersion();
        setVersion(appVersion);
      }
    };

    setAppVersion();
  }, []);

  const handleReload = () => {
    window.location.reload();
  };

  const handleHelp = () => {
    window.open('https://support.qq.com/product/658138', '_blank');
  };

  const handleLogout = () => {
    setSnackbarOpen(true);
  };

  const confirmLogout = () => {
    setSnackbarOpen(false);
    localStorage.removeItem('token');
    window.location.href = '#/login';
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const showAppBar = location.pathname !== '/' && location.pathname !== '/login' && location.pathname !== '/register' && location.pathname !== '/officialwebsite';
  const isOfficialWebsite = location.pathname === '/' || location.pathname === '/officialwebsite';

  return (
    <>
      <CssBaseline />
      {showAppBar && (
        <>
          <AppBarComponent handleReload={handleReload} handleHelp={handleHelp} handleLogout={handleLogout} toggleDrawer={toggleDrawer} version={version} />
          <DrawerComponent handleNavigate={handleNavigate} drawerOpen={drawerOpen} />
        </>
      )}
      {isOfficialWebsite ? (
        <Routes>
          <Route path="/" element={<OfficialWebsite />} />
          <Route path="/officialwebsite" element={<OfficialWebsite />} />
        </Routes>
      ) : (
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: showAppBar && drawerOpen ? `calc(100% - ${drawerWidthPercentage}%)` : '100%',
            ml: showAppBar && drawerOpen ? `${drawerWidthPercentage}%` : 0,
            transition: 'width 0.3s ease, margin-left 0.3s ease',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg">
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/article/:id" element={<Article />} />
              <Route path="/articleList" element={<ArticleList />} />
              <Route path="/generate" element={<Generate />} />
              <Route path="/rewrite" element={<Rewrite />} />
              <Route path="/setting" element={<Setting />} />
              <Route path="/pay" element={<Pay />} />
              <Route path="/bind" element={<Bind />} />
              <Route path="/bindcallback" element={<BindCallback />} />
              {/* TODO: Add a Help route */}
              <Route path="/help" element={<div>Help Center Content</div>} />
            </Routes>
          </Container>
        </Box>
      )}

      <Snackbar
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="warning"
          action={
            <Box>
              <Button color="inherit" size="small" onClick={confirmLogout}>
                确认
              </Button>
              <Button color="inherit" size="small" onClick={handleCloseSnackbar}>
                取消
              </Button>
            </Box>
          }
        >
          您确定要退出吗？
        </Alert>
      </Snackbar>
    </>
  );
};

const AppWrapper = () => (
  <Router>
    <ToastContainer position="top-center" />
    <App />
  </Router>
);

export default AppWrapper;
