import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, TextField, Typography, Container, Box, Grid, Checkbox, FormControlLabel } from '@mui/material';
import { parseResponse } from './components/http';
import config from './config';
import { toast } from 'react-toastify';
import { clearAuthLocalStorage } from './components/localStorageHelper';

function Register() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [buttonText, setButtonText] = useState(`发送验证码`);
  const [isAgreed, setIsAgreed] = useState(false);

  const handleSendVerificationCode = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error('请输入有效的电子邮件地址');
      return;
    }

    setIsButtonDisabled(true); // 禁用按钮
    setButtonText('60s内限发一次');

    let seconds = 60;
    const countdownInterval = setInterval(() => {
      seconds -= 1;
      setButtonText(`${seconds}s`);
      if (seconds <= 0) {
        clearInterval(countdownInterval);
        setIsButtonDisabled(false);
        setButtonText('发送验证码');
      }
    }, 1000);

    try {
      const url = `${config.BASE_URL}/user/verificationcode`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email }),
      });

      const data = await parseResponse(response);

      if (!response.ok) {
        console.error('发送验证码失败:', data);
        throw new Error(`发送验证码失败: ${data}`);
      }

      console.log('验证码已发送:', data);
      toast.success('验证码已发送，请查收。'); // 使用alert提供反馈
    } catch (error) {
      console.error('发送验证码错误:', error);
      toast.error(`发生了一点小问题: ${error.message || '未知错误'}`); // 使用alert提供反馈
      clearInterval(countdownInterval); // 发生错误时清除倒计时
      setIsButtonDisabled(false);
      setButtonText('发送验证码');
    }
  };


  const handleSubmit = async (event) => {
    event.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error('请输入有效的电子邮件地址');
      return;
    }

    if (password !== passwordConfirm) {
      toast.error('两次输入的密码不一致，请重新输入'); // 检查密码是否匹配
      return; // 如果不匹配，阻止表单提交
    }

    if (!isAgreed) {
      toast.warn('请阅读并同意用户协议与隐私政策');
      return; // 如果未勾选，阻止表单提交
    }

    try {
      const response = await fetch(`${config.BASE_URL}/user/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          password: password,
          email: email,
          verificationCode: verificationCode,
        }),
      });

      const data = await parseResponse(response);

      if (!response.ok) {
        console.error('注册失败详情:', data);
        throw new Error(`注册失败: ${data}`);
      }

      console.log('注册成功:', data);
      // 根据需要处理注册成功后的逻辑，例如自动登录、跳转到登录页等
      toast.success('注册成功，请登录！');
      clearAuthLocalStorage();
      navigate('/login');
    } catch (error) {
      console.error('注册错误:', error);
      toast.error(`发生了一点小问题: ${error.message || '未知错误'}`);
    }
  };


  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          注册
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="电子邮件地址"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="密码"
            type="password"
            id="password"
            autoComplete="new-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="passwordConfirm"
            label="确认密码"
            type="password"
            id="passwordConfirm"
            autoComplete="new-password"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
          />
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={9}>
              <TextField
                fullWidth
                margin="normal"
                required
                id="verificationCode"
                label="验证码"
                name="verificationCode"
                autoComplete="off"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                sx={{ height: 56 }} // 根据实际情况调整，确保和按钮高度一致
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                onClick={handleSendVerificationCode}
                fullWidth
                variant="outlined"
                disabled={isButtonDisabled}
                sx={{
                  mt: '8px', // 调整 marginTop 使按钮与输入框在视觉上对齐
                  height: '56px', // 根据实际情况调整，确保和输入框高度一致
                }}
              >
                {buttonText}
              </Button>
            </Grid>
          </Grid>
          <FormControlLabel
            control={
              <Checkbox
                checked={isAgreed}
                onChange={(e) => setIsAgreed(e.target.checked)}
                color="primary"
              />
            }
            label={<span>我已阅读并同意<Link to="/terms">用户协议</Link>与<Link to="/privacy">隐私政策</Link></span>}
            sx={{ mt: 2 }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            注册
          </Button>
          <Link to="/login" style={{ textDecoration: 'none' }}>
            <Button fullWidth variant="text">{"已有账号？立即登录"}</Button>
          </Link>
        </Box>
      </Box>
    </Container>
  );
}

export default Register;
